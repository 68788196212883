import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataset: [],
  reportPackageDataset: [],
  reviewers: [],
  reportPackageReviewers: [],
  datasetReviewers: [],
  addReviewer: {
    OrderNo: "",
    UserName: "",
    ApproverId: "",
    IsAllowEdit: true,
    Id: 0,
    CwfApproverId: "",
  },
  isDataset: false,
  isProperty: false,
  datasetId: "",
  reportPackageId: "",
  fundOrPropertyId: "",
};
//test
const validateValues = (state, data) => {

  const existingIndex = state.reviewers.findIndex(
    (reviewer) => reviewer.Id === data.Id
  );
  if (existingIndex !== -1) {
    state.reviewers[existingIndex] = data;
  } else {
    state.reviewers.push(data);
  }
};

const validateReportPackageReviewers = (state, data) => {
  const existingIndex = state.reportPackageReviewers.findIndex(
    (reviewer) => reviewer.Id === data.Id
  );
  if (existingIndex !== -1) {
    state.reportPackageReviewers[existingIndex].OrderNo = data.OrderNo;
    state.reportPackageReviewers[existingIndex].UserName = data.UserName;
    state.reportPackageReviewers[existingIndex].ApproverId = data.ApproverId;
    state.reportPackageReviewers[existingIndex].IsAllowEdit = data.IsAllowEdit;

  } else {
    state.reportPackageReviewers.push(data);
  }
};

const validateDatasetReviewers = (state, data) => {
  const existingIndex = state.datasetReviewers.findIndex(
    (reviewer) => reviewer.Id === data.Id
  );
  if (existingIndex !== -1) {
    state.datasetReviewers[existingIndex].OrderNo = data.OrderNo;
    state.datasetReviewers[existingIndex].UserName = data.UserName;
    state.datasetReviewers[existingIndex].ApproverId = data.ApproverId;
    state.datasetReviewers[existingIndex].IsAllowEdit = data.IsAllowEdit;
  } else {
    state.datasetReviewers.push(data);
  }
};

const deleteReviwer = (state, data) => {
  const deleteId = data.Id || data.id
  const filterValues = state.reviewers.filter(
      (item) => item.Id !== deleteId
    );
  state.reviewers = filterValues;
};

const deleteReportPackageReviwer = (state, data) => {
  const deleteId = data.Id || data.id
  const filterValues = state.reportPackageReviewers.filter(
    (item) => item.Id !== deleteId
  );
  state.reportPackageReviewers = filterValues;
};

const deleteDatasetReviwer = (state, data) => {
  const deleteId = data.Id || data.id
  const filterValues = state.datasetReviewers.filter(
      (item) => item.Id !== deleteId
    );
  state.datasetReviewers = filterValues;
};

export const configureSlice = createSlice({
  name: "configure",
  initialState,
  reducers: {
    getDataset: (state, action) => {
      state.dataset = action.payload;
    },
    getReportPackageDataset: (state, action) => {
      state.reportPackageDataset = action.payload;
    },
    addReviewerToTable: (state, action) => {
      if (action.payload === 0) {
        state.reviewers = [];
      } else {
        state.reviewers.length === 0
          ? state.reviewers.push(action.payload)
          : validateValues(state, action.payload);
      }
    },
    addReportPackageReviewersReviewerToTable: (state, action) => {
      if (action.payload === 0) {
        state.reportPackageReviewers = [];
      } else if (state.reportPackageReviewers.length === 0) {
        state.reportPackageReviewers.push(action.payload);
      } else {
        validateReportPackageReviewers(state, action.payload);
      }
    },
    addDatasetReviewerToTable: (state, action) => {
      if (action.payload === 0) {
        state.datasetReviewers = [];
        
      } else if (state.datasetReviewers.length === 0) {
        state.datasetReviewers.push(action.payload);
      } else {
        validateDatasetReviewers(state, action.payload);
      }
    },
    addReviewer: (state, action) => {    
      if (action.payload.OrderNo !== undefined) {
        state.addReviewer.OrderNo = action.payload.OrderNo;
      }
      if (action.payload.UserName !== undefined) {
        state.addReviewer.UserName = action.payload.UserName;
      }
      if (action.payload.ApproverId !== undefined) {
        state.addReviewer.ApproverId = action.payload.ApproverId;
      }
      if (action.payload.CwfApproverId !== undefined) {
        state.addReviewer.CwfApproverId = action.payload.CwfApproverId;
      }
      state.addReviewer.Id = action.payload.Id;
      state.addReviewer.IsAllowEdit = action.payload.IsAllowEdit;
    },
    
    setSelectedWorkflowIds: (state, action) => {
      state.datasetId = action.payload.DatasetId;
      state.reportPackageId = action.payload.ReportPackageId;
      state.fundOrPropertyId = action.payload.FundOrPropertyId;
    },
    isDataset: (state, action) => {
      state.isDataset = action.payload;
    },
    removeReviwer: (state, action) => {
      deleteReviwer(state, action.payload);
    },
    removeReportPackageReviwer: (state, action) => {
      deleteReportPackageReviwer(state, action.payload);
    },
    removeDatasetReviwer: (state, action) => {
      deleteDatasetReviwer(state, action.payload);
    },
    checkProperty: (state, action) => {
      state.isProperty = action.payload;
    },
  },
});

export const {
  getDataset,
  getReportPackageDataset,
  addReviewerToTable,
  addReviewer,
  isDataset,
  removeReviwer,
  addReportPackageReviewersReviewerToTable,
  removeReportPackageReviwer,
  addDatasetReviewerToTable,
  removeDatasetReviwer,
  checkProperty,
  setSelectedWorkflowIds,
} = configureSlice.actions;

export default configureSlice.reducer;
