import axios from 'axios';

const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(config => {
  // Show the spinner before the request is sent
  //console.log("Show the spinner before the request is sent");
  showSpinner();
  return config;
}, error => {
  hideSpinner(); // Hide the spinner if there's an error in the request
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(response => {
  // Hide the spinner when the response is received
  //console.log("Hide the spinner when the response is received");
  hideSpinner();
  return response;
}, error => {
  hideSpinner(); // Hide the spinner if there's an error in the response
  return Promise.reject(error);
});

// Function to show the spinner
function showSpinner() {
  // You may use the state management library or a global state to manage the loading state
  // For simplicity, we'll use a DOM manipulation here
  const spinner = document.getElementById('global-spinner');
  //console.log("showSpinner",spinner);
  if (spinner) {
    spinner.style.display = 'block';
  }
}

// Function to hide the spinner
function hideSpinner() {
  const spinner = document.getElementById('global-spinner');
 // console.log("hideSpinner",spinner);
  if (spinner) {
    spinner.style.display = 'none';
  }
}

export default instance;